import React from "react"

import { Link } from "gatsby"

import Layoutcs from "../../components/layoutcs/layoutcs"
import SEO from "../../components/seo"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import Footer from "../../components/footer/footer"

import "../index.scss"
import "../services.scss"

import ImgLogo1 from "../../images/techstack/figma.svg"
import ImgLogo2 from "../../images/techstack/adobe-creative-suite.svg"
import ImgLogo3 from "../../images/techstack/trello.svg"
import ImgLogo4 from "../../images/techstack/toggl.svg"
import ImgLogo5 from "../../images/techstack/slack.svg"
import ImgLogo6 from "../../images/techstack/powerbi.svg"

import ImgLogo7 from "../../images/techstack/php.svg"
import ImgLogo8 from "../../images/techstack/nette.svg"
import ImgLogo9 from "../../images/techstack/mysql.svg"
import ImgLogo10 from "../../images/techstack/elasticsearch.svg"
import ImgLogo11 from "../../images/techstack/docker.svg"
import ImgLogo12 from "../../images/techstack/kubernetes.svg"
import ImgLogo13 from "../../images/techstack/angular.svg"
import ImgLogo14 from "../../images/techstack/bootstrap.svg"
import ImgLogo15 from "../../images/techstack/github.svg"
import ImgLogo16 from "../../images/techstack/bitbucket.svg"
import ImgLogo17 from "../../images/techstack/gitlab.svg"

import ImgLogo18 from "../../images/techstack/sentry.svg"
import ImgLogo19 from "../../images/techstack/uptimerobot.svg"
import ImgLogo20 from "../../images/techstack/aws.svg"
import ImgLogo21 from "../../images/techstack/selenium.svg"
import ImgLogo22 from "../../images/techstack/owasp.svg"

import VideoBg from "../../videos/ftrs-video.mp4"

const Sluzby = () => (

    <Layoutcs>
        <SEO title="Produkty a služby" description="Provedeme vás od myšlenky k hotovému produktu." />

		<div className="ftrs-background" data-sal="fade" data-sal-duration="2000">
			<video autoPlay="autoplay" muted loop className="ftrs-hero__video ftrs-hero__video--dark">
				<source media="(orientation: landscape)" src={VideoBg} type="video/mp4" />
			</video>
		</div>

        <Container className="ftrs-content ftrs-services">

            <h1 data-sal="fade" data-sal-duration="1000" className="ftrs-h1">Produkty a&nbsp;služby</h1>

            <p data-sal="fade" data-sal-duration="1000" className="ftrs-services__paragraph text-basic mt-5">Vytvoření hodnotného produktu může být zdlouhavá cesta plná validace, testování, designování, návrhů a&nbsp;změn architektury softwaru, UX/UI, údržby a&nbsp;optimalizace.</p>
            <p data-sal="fade" data-sal-duration="1000"><strong>Rádi Vás touto cestou provedeme, abyste snadno dosáhli Vašich cílů.</strong></p>


			<h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-h2--big mb2 mt4">
				<Link to="./sprava-php-aplikaci">
					Upgrade PHP aplikací
				</Link>
			</h2>

			<Row>
				<Col lg="6">
					<p data-sal="fade" data-sal-duration="1000" className="ftrs-services__header-text">
						Svěřte nám svou starší aplikaci - pomůžeme Vám s&nbsp;analýzou a&nbsp;realizací jejího upgradu. Máme mnohaleté zkušenosti s&nbsp;údržbou a&nbsp;rozvojem PHP aplikací. Daří se nám ze&nbsp;zastaralého kódu vytvářet moderní, udržitelné a&nbsp;bezpečné aplikace, které dobře vypadají a&nbsp;mohou se bez&nbsp;problémů rozšířit o&nbsp;nové funkcionality.
					</p>
				</Col>
				<Col lg="3">
					<ul data-sal="fade" data-sal-duration="1000" className="mb2">
						<li>analýza</li>
						<li>update technologie</li>
						<li>bezpečnost</li>
						<li>výkon a&nbsp;stabilita</li>
						<li>škálování</li>
					</ul>
				</Col>
				<Col lg="3">
					<ul data-sal="fade" data-sal-duration="1000" className="mb2">
						<li>nové funkce</li>
						<li>modernizace designu</li>
						<li>automatizace</li>
						<li>napojení na&nbsp;další systémy</li>
					</ul>
				</Col>
			</Row>

			<div data-sal="fade" data-sal-duration="2000" className="ftrs-hero__buttons">
				<Link className="ftrs-button ftrs-button--big mt-4" to="./sprava-php-aplikaci">
					Více o upgrade PHP aplikací
				</Link>
			</div>

		</Container>

		<div className="ftrs-services__box-stage">
			<Container>

				<Row className="mt2">
					<Col lg="6">
						<h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-h2--big mb2">Projekt, plán a&nbsp;design</h2>
						<p data-sal="fade" data-sal-duration="1000" className="ftrs-services__header-text">
							Pomůžeme Vám naplánovat a&nbsp;zorganizovat Váš projekt. Společně s&nbsp;týmem zkušených profesionálů posouváme každý projekt na&nbsp;novou úroveň. Uděláme Váš digitální produkt skvělým.
						</p>
						<ul data-sal="fade" data-sal-duration="1000" className="mb2">
							<li>architektura produktu a&nbsp;design</li>
							<li>porozumění požadavkům Vašeho businessu</li>
							<li>inovace a&nbsp;kreativita</li>
							<li>plánování a&nbsp;prioritizace</li>
							<li>řízení projektu</li>
						</ul>
					</Col>
					<Col lg="6">
						<div className="ftrs-services__icons">
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo1} alt="Figma" />
								</div>
								<div className="ftrs-techstack__header">Figma</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo3} alt="Trello" />
								</div>
								<div className="ftrs-techstack__header">Trello</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo4} alt="Toggl" />
								</div>
								<div className="ftrs-techstack__header">Toggl</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo5} alt="Slack" />
								</div>
								<div className="ftrs-techstack__header">Slack</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo6} alt="Power BI" />
								</div>
								<div className="ftrs-techstack__header">Power BI</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo2} alt="Adobe Creative Suite" />
								</div>
								<div className="ftrs-techstack__header">Creative Suite</div>
							</div>
						</div>
					</Col>
				</Row>

				<Row className="mt4">
					<Col lg="6">
						<h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-h2--big mb2">Precizní vývoj na&nbsp;míru</h2>
						<p data-sal="fade" data-sal-duration="1000" className="ftrs-services__header-text">
							S naším týmem zkušených developerů vyvineme Vaši aplikaci s&nbsp;ohledem na plán, termíny a&nbsp;bezpečnostní standardy. Za&nbsp;použití nejnovějších technologií a&nbsp;trendů pomůžeme vzniknout produktu, který budou Vaši zákazníci a&nbsp;uživatelé rádi používat.
						</p>
						<ul data-sal="fade" data-sal-duration="1000" className="mb2">
							<li>vývoj webových a&nbsp;mobilních aplikací</li>
							<li>nejnovější technologie</li>
							<li>quality assurance</li>
							<li>důraz na&nbsp;bezpečnost</li>
						</ul>
					</Col>
					<Col lg="6">
						<div className="ftrs-services__icons">
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo7} alt="PHP" />
								</div>
								<div className="ftrs-techstack__header">PHP</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo8} alt="Nette" />
								</div>
								<div className="ftrs-techstack__header">Nette</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo9} alt="MySQL" />
								</div>
								<div className="ftrs-techstack__header">MySQL</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo10} alt="Elasticsearch" />
								</div>
								<div className="ftrs-techstack__header">Elasticsearch</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo11} alt="Docker" />
								</div>
								<div className="ftrs-techstack__header">Docker</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo12} alt="Kubernetes" />
								</div>
								<div className="ftrs-techstack__header">Kubernetes</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo13} alt="AngularJS" />
								</div>
								<div className="ftrs-techstack__header">AngularJS</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo14} alt="Bootstrap" />
								</div>
								<div className="ftrs-techstack__header">Bootstrap</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo15} alt="Github" />
								</div>
								<div className="ftrs-techstack__header">Github</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo16} alt="Bitbucket" />
								</div>
								<div className="ftrs-techstack__header">Bitbucket</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo17} alt="Gitlab" />
								</div>
								<div className="ftrs-techstack__header">Gitlab</div>
							</div>
						</div>
					</Col>
				</Row>


				<Row className="mt4">
					<Col lg="6">
						<h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-h2--big mb2">Digital partnering</h2>
						<p data-sal="fade" data-sal-duration="1000" className="ftrs-services__header-text">
							Spuštěním produktu to nekončí. Skvělý digitální produkt potřebuje kontinuální vývoj a&nbsp;podporu. Náš tým rád posouvá projekty k&nbsp;vyšším metám. Naši devops experti milují práci s&nbsp;cloudem, který je bezpečný a&nbsp;škálovatelný.
						</p>
						<ul data-sal="fade" data-sal-duration="1000" className="mb2">
							<li>průběžná digitální podpora</li>
							<li>údržba</li>
							<li>devops</li>
						</ul>
					</Col>
					<Col lg="6">
						<div className="ftrs-services__icons">
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo20} alt="AWS" />
								</div>
								<div className="ftrs-techstack__header">AWS</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo18} alt="Sentry" />
								</div>
								<div className="ftrs-techstack__header">Sentry</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo19} alt="Uptimerobot" />
								</div>
								<div className="ftrs-techstack__header">Uptimerobot</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo21} alt="Selenium" />
								</div>
								<div className="ftrs-techstack__header">Selenium</div>
							</div>
							<div className="ftrs-services__icon" data-sal="fade" data-sal-duration="1000">
								<div className="ftrs-techstack__item">
									<img loading="lazy" className="ftrs-techstack__img" src={ImgLogo22} alt="Owasp" />
								</div>
								<div className="ftrs-techstack__header">Owasp</div>
							</div>
						</div>
					</Col>
				</Row>

			</Container>
		</div>

		<Container>

			<h2 className="ftrs-h2 ftrs-h2--big">V čem jsme nejlepší?</h2>

            <Row>
                <Col xs="12" xl="4">
                    <h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-services__header">Odvětví</h2>
                    <ul data-sal="fade" data-sal-duration="1000" className="ftrs-services__list">
                        <li>Bankovnictví</li>
                        <li>Automotive</li>
                        <li>Pojišťovnictví</li>
                        <li>Telco</li>
                        <li>Maloobchod</li>
                        <li>Chytrá města</li>
                    </ul>
                </Col>
                <Col xs="12" xl="4">
                    <h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-services__header">Kategorie</h2>
                    <ul data-sal="fade" data-sal-duration="1000" className="ftrs-services__list">
                        <li>Správa obsahu</li>
                        <li>Interní systémy</li>
                        <li>Sběr dat a reporting</li>
                        <li>Business Intelligence</li>
                        <li>Monitoring konkurence</li>
                        <li>Customer experience</li>
                    </ul>
                </Col>
                <Col xs="12" xl="4">
                    <h2 data-sal="fade" data-sal-duration="1000" className="ftrs-h2 ftrs-services__header">Odbornost</h2>
                    <ul data-sal="fade" data-sal-duration="1000" className="ftrs-services__list">
                        <li>Datová analytika</li>
                        <li>Komplexní komunikace napříč organizací</li>
                        <li>Smart emailing</li>
                        <li>UX design</li>
                    </ul>
                </Col>
            </Row>

        </Container>

		<Footer lang='cs'></Footer>

    </Layoutcs>

)

export default Sluzby